import React from "react";
import LineGraphic from ".";

const CommonLines = ({ variant, ...overrides }) => {
	switch (variant) {
		case "1":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					size="50vw"
					right="0"
					bottom="0"
					{...overrides}
					line="squiggly"
				/>
			);
		case "2":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					size="35vw"
					left="0"
					bottom="0"
					{...overrides}
					line="squiggly_reverse"
				/>
			);
		case "3":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					size="35vw"
					right="0"
					bottom="0"
					rotate="160"
					x="-15%"
					y="-30%"
					{...overrides}
					line="single_loop_with_curl"
				/>
			);
		case "4":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					size="35vw"
					top="0"
					right="0"
					rotate="195"
					flip
					x="45%"
					y="3%"
					{...overrides}
					line="single_loop_with_curl"
				/>
			);
		case "5":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					thickness="12"
					size="30vw"
					top="0"
					left="0"
					rotate="160"
					x="47%"
					y="10%"
					{...overrides}
					line="single_loop_with_curl"
				/>
			);
		case "6":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					size="20vw"
					top="0"
					right="0"
					x="2%"
					{...overrides}
					line="swoop"
				/>
			);
		case "7":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					size="20vw"
					top="0"
					left="0"
					x="2%"
					flip
					{...overrides}
					line="swoop"
				/>
			);
		case "8":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					size="20vw"
					right="0"
					bottom="0"
					rotate="117"
					x="-40%"
					y="-40%"
					{...overrides}
					line="double_switch"
				/>
			);
		case "9":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					size="35vw"
					top="0"
					left="0"
					rotate="165"
					x="45%"
					y="3%"
					{...overrides}
					line="single_loop_with_curl"
				/>
			);
		case "10":
			return (
				<LineGraphic
					stroke="var(--primary-green-600)"
					thickness="12"
					size="30vw"
					top="0"
					right="0"
					rotate="200"
					flip
					x="47%"
					y="10%"
					{...overrides}
					line="single_loop_with_curl"
				/>
			);
		case "11":
			return (
				<LineGraphic
					stroke="var(--primary-green-400)"
					size="25vw"
					thickness="5"
					right="0"
					bottom="0"
					top="550px"
					left="398px"
					rotate="35"
					{...overrides}
					line="squiggly"
				/>
			);
		default:
			return <></>;
	}
};

export default CommonLines;
